import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import Cognito from '../../components/Cognito'



export default class Index extends React.Component {
  

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Get In Touch With The Spider Guy</h1>
              <div className="purplephone">
                    Tap To Call: <a href="tel:586-463-1280">586-463-1280</a>
                </div>
              <Cognito />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
